import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite"
import __global from "../../__global"
import __invites from "./__invites"
import {FormButton, FormInput} from "../../_components/FormElements";
import Rollover from "../../_components/Rollover";
import {runInAction} from "mobx";
import {Col, Row} from "antd";
import GlobalTableBlock from "../../_components/_table/GlobalTable";
import {formatDate, formatDateWithTime} from "../../_components/__helpers";

const InvitesPage = observer(() => {
    useEffect(() => {
        __global.getList('invites')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'Код',
            key: 'code',
            sort: true
        },
        {
            title: 'Использован',
            key: 'used_by',
            type: 'customRender',
            render: (_, r) => <>{r?.user ? (<>{r.user.name} (<b>id: {r.user.id}</b>)</>) : (<>Нет</>)}</>
        },
        {
            title: 'Дата использования',
            key: 'used_at',
            sort: true,
            type: 'date'
        },
        {
            title: '',
            key: 'used_at',
            type: 'customRender',
            render: (_) => <></>
        },
    ]

    return <div>
        <div className="workarea__section">
            <Row gutter={[9, 9]}>
                <Col>
                    <FormButton
                        onClick={() => __invites.toggleRollover()}
                        className="form__button-blue"
                    >Генерировать новые</FormButton>
                </Col>

                <Col>
                    <FormButton
                        onClick={() => __invites.download()}
                        className="form__button-green"
                    >Скачать свободные</FormButton>
                </Col>
            </Row>
        </div>

        <div className="workarea__section invites">
            <Row gutter={[9, 9]}>
                <Col xs={24}>
                    <GlobalTableBlock
                        title="Список приглашений"
                        columns={columns}
                    />
                </Col>
            </Row>
        </div>

        <Rollover
            opened={__invites.rolloverOpened}
            close={_ => __invites.toggleRollover()}
            title="Генерировать новые приглашения"
            onSubmit={e => {
                e.preventDefault()
                __invites.generate()
            }}
            submitText="Генерировать"
            size="small"
        >
            <FormInput
                onChange={e => runInAction(() => __invites.generateCount = e.target.value)}
                value={__invites.generateCount}
                placeholder="Количество"
            />
        </Rollover>
    </div>
})

export default InvitesPage
