import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite"
import __global from "../../__global"
import __dashboard from "./__dashboard"

const DashboardPage = observer(() => {

    return <></>
})

export default DashboardPage
