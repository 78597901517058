import React from 'react'
import {observer} from 'mobx-react-lite'
import __global from "../__global"
import { Button } from 'antd'
import LogoutIcon from '../../img/icons/closeIcon.svg'

const Rollover = observer((
    {
        opened,
        close,
        title,
        onSubmit,
        submitText = 'Сохранить',
        onCancel = close,
        cancelText = 'Отменить',
        size,
        buttonsDisabled = __global.waitForAnswer,
        children,
        hideButtons = false
    }
) => {

    let closeFunction = close

    let mask = document.querySelector('.rollover__mask')
    if(mask){
        let listenerExists = mask.dataset.listener
        if(listenerExists !== 'click'){
            mask.dataset.listener = 'click'
            mask.addEventListener('click', _ => closeFunction())
        }
    }

    let className = 'rollover'
    if(opened === true){
        className += ' rollover__opened'
        if(mask) mask.classList.add('rollover__mask-opened')
    } else {
        if(mask) mask.classList.remove('rollover__mask-opened')
    }

    if(size){
        className += ` rollover__${size}`
    }

    return (
        <div className={className}>
            <div className="workarea__section">
                <div className="rollover__header">
                    <div className="rollover__header-heading">{title}</div>
                    <div onClick={_ => closeFunction()} className="rollover__close">
                        <img src={LogoutIcon} alt=""/>
                    </div>
                </div>
            </div>
            <div className="workarea__section">
                {hideButtons === false ? (
                    <form onSubmit={e => onSubmit(e)} className="form">
                        {children}
                        <div className="form__row rollover__sticky-bottom">
                            <Button
                                disabled={buttonsDisabled}
                                className="form__button form__button-blue"
                                onClick={e => onSubmit(e)}
                            >
                                {submitText}
                            </Button>
                            <Button
                                disabled={buttonsDisabled}
                                className="form__button form__button-transparent"
                                onClick={onCancel}
                            >
                                {cancelText}
                            </Button>
                        </div>
                    </form>
                ) : (<>{children}</>)}
            </div>
        </div>
    )
})

export default Rollover
