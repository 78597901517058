import {makeAutoObservable} from "mobx"
import __global from "../../__global"

class dashboardController {
    constructor() {
        makeAutoObservable(this)
    }

}

export default new dashboardController()
