import React, {useEffect} from 'react'
import {observer, Observer} from "mobx-react-lite"
import __global from "../../__global"
import __auth from "../auth/__auth"
import __users from "./__users"
import {Col, Popconfirm, Popover, Row} from "antd"
import {formatDate, formatDateWithTime} from "../../_components/__helpers"
import {FormButton, FormInput, FormSelect} from "../../_components/FormElements"
import Rollover from "../../_components/Rollover"
import {runInAction} from "mobx"
import GlobalTableBlock from "../../_components/_table/GlobalTable";

const UsersPage = observer(() => {
    useEffect(() => {
        __global.getList('users')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'ФИО',
            key: 'name',
            sort: true
        },
        {
            title: 'Номер телефона',
            key: 'phone',
            sort: true
        },
        {
            title: 'Дата рождения',
            key: 'birthday',
            type: 'customRender',
            render: (_) => (formatDate(_))
        },
        {
            title: 'Заблокирован',
            key: 'blocked',
            sort: true,
            type: 'customRender',
            render: (_, r) => {
                if(_ === 1){
                    return (
                        <Popover title="Причина" content={(<Row gutter={[9,9]}>
                            <Col span={24}>{r?.reason ?? ''}</Col>
                            <Col span={24}>{formatDateWithTime(r.blocked_at)}</Col>
                        </Row>)}>Да</Popover>
                    )
                }
                return(
                    <>Нет</>
                )
            }
        },
        {
            title: 'Дата создания',
            key: 'created_at',
            sort: true,
            type: 'date'
        },
        {
            title: 'Действия',
            key: 'actions',
            type: 'customRender',
            render: (_, r) => {
                return (
                    <Observer>
                        {() => (
                            <Row gutter={[9,9]} justify="end">
                                <Col>
                                    {r.blocked === 1 ? (
                                        <Popconfirm
                                            placement="topRight"
                                            title={`Разблокировать пользователя ${r.name} ?`}
                                            onConfirm={_ => __users.blockUser(0, r.id)}
                                            okText="Да"
                                            cancelText="Нет"
                                        >
                                            <FormButton
                                                className="form__button-green form__button-small"
                                            >Разблокировать</FormButton>
                                        </Popconfirm>
                                    ) : (
                                        <Popover
                                            title="Причина"
                                            trigger="click"
                                            content={(<>
                                                <Row gutter={[9,9]}>
                                                    <Col span={24}>
                                                        <FormInput
                                                            onChange={e => runInAction(() => __users.blockReason = e.target.value)}
                                                            value={__users.blockReason}
                                                            placeholder="Укажите причину блокировки"
                                                        />
                                                    </Col>

                                                    <Col span={24}>
                                                        <FormButton
                                                            onClick={() => __users.blockUser(1, r.id)}
                                                            className="form__button-danger"
                                                        >Заблокировать</FormButton>
                                                    </Col>
                                                </Row>
                                            </>)}
                                        >
                                            <FormButton
                                                className="form__button-gray form__button-small"
                                            >Заблокировать</FormButton>
                                        </Popover>
                                    )}
                                </Col>
                                <Col>
                                    <FormButton
                                        onClick={() => {
                                            __users.getUser(r.id)
                                            __users.toggleRollover()
                                        }}
                                        className="form__button-orange form__button-small"
                                    >Редактировать</FormButton>
                                </Col>
                                {/*<Col>*/}
                                {/*    <Popconfirm*/}
                                {/*        title={"Вы действительно хотите удалить пользователя " + r.login + "?"}*/}
                                {/*        onConfirm={_ => __users.deleteUser(r.id)}*/}
                                {/*        okText="Да"*/}
                                {/*        cancelText="Нет"*/}
                                {/*    >*/}
                                {/*        <FormButton*/}
                                {/*            className="form__button-danger form__button-small"*/}
                                {/*        >Удалить</FormButton>*/}
                                {/*    </Popconfirm>*/}
                                {/*</Col>*/}
                            </Row>
                        )}
                    </Observer>
                )
            }
        },
    ]

    return <>
        <div>
            {/*<div className="workarea__section">*/}
            {/*    <Row gutter={[9,9]}>*/}
            {/*        <Col>*/}
            {/*            <FormButton*/}
            {/*                onClick={() => __users.toggleRollover()}*/}
            {/*                className="form__button-blue"*/}
            {/*            >Добавить нового</FormButton>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}

            <div className="workarea__section users">
                <Row gutter={[9, 9]}>
                    <Col xs={24}>
                        <form onSubmit={e => {
                            e.preventDefault()
                            __global.getList()
                        }}>
                            <Row gutter={[9,9]}>
                                <Col xs={24} md={8} lg={6} xl={4}>
                                    <FormInput
                                        onChange={e => __global.changeFilters('name', e.target.value)}
                                        value={__global.filtersValues.name?.value}
                                        placeholder="ФИО"
                                    />
                                </Col>

                                <Col xs={24} md={8} lg={6} xl={4}>
                                    <FormInput
                                        onChange={e => __global.changeFilters('phone', e.target.value)}
                                        value={__global.filtersValues.phone?.value}
                                        placeholder="Номер телефона"
                                    />
                                </Col>

                                <Col>
                                    <FormButton
                                        htmlType="submit"
                                        className="form__button-blue"
                                    >Поиск</FormButton>
                                </Col>

                                <Col>
                                    <FormButton
                                        onClick={() => __global.clearFilters()}
                                        className="form__button-danger"
                                    >Очистить</FormButton>
                                </Col>
                            </Row>
                        </form>
                    </Col>

                    <Col xs={24}>
                        <GlobalTableBlock
                            title="Список пользователей"
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        </div>

        <Rollover
            opened={__users.rolloverOpened}
            close={_ => __users.toggleRollover()}
            title="Редактировать пользователя"
            onSubmit={e => {
                e.preventDefault()
                __users.saveUser()
            }}
            size="small"
        >
            <FormInput
                disabled={__global.waitForAnswer}
                onChange={e => __auth.change('name', e.target.value)}
                value={__auth.user.name}
                placeholder="ФИО"
            />

            <FormInput
                onChange={e => __auth.change('phone', e.target.value)}
                value={__auth.user.phone}
                placeholder="Номер телефона"
            />
        </Rollover>
    </>
})

export default UsersPage
