import React from "react"
import {observer} from 'mobx-react-lite'
import __global from "../../__global";
import TableBlock from "./TableBlock";

const GlobalTableBlock = observer(({title, columns}) => {
    return <>
        <TableBlock
            title={title}
            columns={columns}
            data={__global.list}
            pagination={{
                locale: { items_per_page: 'на странице' },
                size: 'default',
                position: ['bottomRight'],
                current: __global.pagination.page,
                pageSize: __global.pagination.limit,
                total: __global.pagination.total,
                showSizeChanger: true,
                pageSizeOptions: [10, 25, 50, 100, 200],
                onShowSizeChange: (page, size) => __global.setPage(1, size),
                onChange: page => __global.setPage(page)
            }}
            onChange={(pagination, filter, sort) => {
                __global.pagination.limit = pagination.pageSize
                __global.pagination.page = pagination.current
                __global.pagination.orderBy = sort?.column ? sort.field : 'id'
                __global.pagination.orderDirection = sort.order === 'ascend' ? 'ASC' : 'DESC'

                Object.keys(filter).map(filterKey => {
                    if (filter[filterKey]) {
                        __global.changeFilters(filterKey, filter[filterKey], false, true)
                    } else {
                        __global.changeFilters(filterKey, null, false, false)
                    }
                })

                __global.getList()
            }}
        />
    </>
})

export default GlobalTableBlock
