import {makeAutoObservable, runInAction} from 'mobx'
import __global from "../../__global"
import { message } from "antd"
import {clearLocal, saveLocal} from "../../_store/local";
import {clearCookie, saveCookie} from "../../_store/cookies";

class AuthController {
    constructor() {
        makeAutoObservable(this)
    }

    user = {}

    authUser(e) {
        e.preventDefault()
        let data = new FormData()

        let check = this.checkFields()
        if(check !== true) return

        data.append('login', this.user.login)
        data.append('password', this.user.password)

        __global.request('post', 'admin/login', data)
            .then(result => {
                if(result?.token) {
                    this.saveToken(result.token)
                    __global.redirect()
                }
            })
            .catch(error => {
                this.change('password', '')
            })
    }

    checkFields(type = 'auth') {
        if(type === 'auth') {
            if(this.user.login === '') {
                return message.error('Необходимо ввести логин')
            }
            if(this.user.password === '') {
                return message.error('Необходимо ввести пароль')
            }
        }

        if(type === 'update') {
            if(this.user.password !== ''){
                if(this.user.password !== this.user.confirmPassword){
                    return message.error('Пароли должны совпадать')
                }
            } else {
                if(this.user.id === 0){
                    return message.error('Необходимо ввести пароль')
                }
            }
        }

        return true
    }

    change(field, value) {
        this.user[field] = value
    }

    setUser() {
        this.user = {
            id: 0,
            login : '',
            password: '',
            confirmPassword: '',
            code: '',
            name: '',
            telegram: '',
            email: '',
            permissions: 5
        }
    }

    saveToken(token) {
        if(token) {
            saveLocal('User-Token', token)
            saveCookie('User-Token', token)
        }
    }

    logOut() {
        clearCookie()
        clearLocal()
        __global.redirect('/auth')
    }



    waitForAnswer = false
    lockLoginField = false

    admin = {
        id: 0,
        login: '',
        name: '',
        password: '',
        confirmPassword: '',
        code: ''
    }

    list = []

    rolloverOpened = false

    getAdminsList() {
        axios.get('/api/v1/admin')
            .then(result => {
                let tableData = []
                result.data.map((item, i) => {
                    tableData.push({
                        ...item,
                        key: i
                    })
                })
                this.list = tableData
            })
            .catch(error => console.log(error))
    }

    getAdmin(id) {
        this.waitForAnswer = true
        this.lockLoginField = true

        axios.get(`/api/v1/admin/${id}`)
            .then(result => {
                this.admin = result.data
                this.admin.password = ''
                this.admin.confirmPassword = ''
                this.waitForAnswer = false
            })
            .catch(error => console.log(error))
    }

    setAdmin() {
        this.lockLoginField = false

        this.admin = {
            id: 0,
            login: '',
            name: '',
            email: '',
            telegram: '',
            password: '',
            confirmPassword: '',
            permissions: 0
        }
    }

    saveAdmin(e, notAdmin = false) {
        e.preventDefault()

        this.waitForAnswer = true

        if(this.checkFields() === false) return

        let url = '/api/v1/admin'
        if(this.admin.id > 0){
            url += `/${this.admin.id}`

            axios.put(url, {
                name: this.admin.name,
                email: this.admin.email,
                telegram: this.admin.telegram,
                password: sha1(this.admin.password),
                permissions: this.admin.permissions
            })
                .then(result => {
                    if(notAdmin){
                        message.success('Настройки успешно сохранены')
                        this.admin.password = ''
                        this.admin.confirmPassword = ''
                    } else {
                        message.success('Пользователь успешно сохранён')
                        this.admin.login = ''
                        this.rolloverOpened = false
                        this.getAdminsList()
                    }
                    this.waitForAnswer = false
                })
                .catch(error => message.error(error.response.data.message))
        } else {
            let facts = new FormData()

            facts.append('login', this.admin.login)
            facts.append('name', this.admin.name)
            facts.append('email', this.admin.email)
            facts.append('telegram', this.admin.telegram)
            facts.append('password', sha1(this.admin.password))
            facts.append('permissions', this.admin.permissions)

            axios.post(url, facts)
                .then(result => {
                    message.success('Пользователь успешно добавлен')
                    this.admin.password = ''
                    this.admin.confirmPassword = ''
                    this.getAdminsList()
                    this.rolloverOpened = false
                    this.waitForAnswer = false
                })
                .catch(error => message.error(error.response.data.message))
        }
    }

    deleteAdmin(id) {
        axios.delete(`/api/v1/admin/${id}`)
            .then(result => {
                this.list = this.list.filter(el => el.id !== id)
                this.rolloverOpened = false
                message.success('Пользователь успешно удалён')
            })
            .catch(error => console.log(error))
    }

    toggleRollover() {
        return this.rolloverOpened = !this.rolloverOpened
    }

    saveToCookies(name, value) {
        const date = new Date()
        date.setTime(date.getTime() + (365*24*60*60*1000))
        let expires = `expires=${date.toUTCString()}`
        document.cookie = `${name}=${value};${expires};path=/`
        return true
    }





    blockReason = ''

    changeReason(text) {
        this.blockReason = text
    }

    blockUser(id) {
        this.waitForAnswer = true

        let fd = new FormData()
        fd.append('id', id)
        fd.append('reason', this.blockReason)

        axios.post('/api/v1/admin/block', fd)
            .then(result => {
                message.success('Пользователь заблокирован')
                this.getAdminsList()

                this.waitForAnswer = false
            })
            .catch(error => {
                message.error(error.response.data.message)
                this.waitForAnswer = false
            })
    }

    unBlockUser(id) {
        this.waitForAnswer = true

        let fd = new FormData()
        fd.append('id', id)

        axios.post('/api/v1/admin/unblock', fd)
            .then(result => {
                message.success('Пользователь разблокирован')
                this.getAdminsList()

                this.waitForAnswer = false
            })
            .catch(error => {
                message.error(error.response.data.message)
                this.waitForAnswer = false
            })
    }
}

export default new AuthController()
