import React from "react"
import {observer} from 'mobx-react-lite'
import FormatFilledTableCell from "./formatFilledTableCell"
import { Table } from "antd"

const TableBlock = observer(({title, columns, data, summary, pagination = null, rowClassName, onChange = null}) => {

    let tableColumns = []
    columns.map(item => {
        if(item.children){
            return tableColumns.push({
                title: item.title,
                children: item.children
            })
        }

        let itemObject = {
            title: item.title,
            dataIndex: item.key,
            key: item.key,
            sortDirections: ['descend', 'ascend'],
            showSorterTooltip: false
        }

        if(item?.filters){
            let filters = []
            item.filters.map(el => {
                filters.push({text: el.label, value: el.value})
            })
            itemObject['filters'] = filters
        }

        if (item.sort) {
            itemObject['sorter'] = () => {}
        }

        if (item.type === 'valute') {
            itemObject['render'] = (_, r) => {
                let className = ''
                if (Number(r[item.key]) === 0) className = 'cell-empty'

                return (
                    <div className={className}>
                        {new Intl.NumberFormat('ru-RU').format(r[item.key])}
                    </div>
                )
            }
        } else if(item.type === 'date') {
            itemObject['render'] = (_, r) => {
                if(r[item.key] === null || r[item.key] === '') return
                let date = new Date(Date.parse(r[item.key]))
                return date.toLocaleDateString("ru-RU") + " " + date.toLocaleTimeString("ru-RU", {timeStyle: 'short'})
            }
        } else if(item.type === 'customRender'){
            itemObject['render'] = item.render
        } else if(item.type === 'percent'){
            itemObject['render'] = (_, r) => {
                let cellStyle = FormatFilledTableCell(r[item.key])
                let className = ''
                if(Number(r[item.key]) === 0) className = 'cell-empty'
                return (
                    <>
                        <div className="cell-percent">
                            <div
                                className="cell-percent-filled"
                                style={{background: cellStyle}}
                            />
                            <div className={className}>
                                {String(r[item.key]).replace(/([^,.\d]+)/gi, '')}%
                            </div>
                        </div>
                    </>
                )
            }
        } else {
            itemObject['render'] = (_, r) => {
                let className = ''
                if(Number(r[item.key]) === 0) className = 'cell-empty'

                return(
                    <div className={className}>
                        {r[item.key]}
                    </div>
                )
            }
        }

        tableColumns.push(itemObject)
    })

    let tableOptions = {
        pagination: pagination,
        columns: tableColumns,
        dataSource: data,
        size: 'small',
    }

    if(onChange) {
        tableOptions['onChange'] = onChange
    }

    if(rowClassName){
        tableOptions['rowClassName'] = rowClassName
    }

    if(summary && summary.length > 0){
        tableOptions['summary'] = () => {
            return (
                <>
                    <Table.Summary.Row>
                        {summary.map((cell, i) => (
                            <Table.Summary.Cell index={i} key={i}>
                                {cell}
                            </Table.Summary.Cell>
                        ))}
                    </Table.Summary.Row>
                </>
            );
        }
    }

    return (
        <div className="info-block">
            <div className="info-block__title">{title}</div>
            <div className="info-block__table">
                <Table {...tableOptions}/>
            </div>
        </div>
    )
})

export default TableBlock
