import React from 'react'
import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import fileDownload from "js-file-download";

class organizationsController {
    constructor() {
        makeAutoObservable(this)
    }

    download() {
        let url = __global.getURLQuery('organizations/download')

        __global.request('get', url, {}, true, {
            responseType: 'blob'
        })
            .then(result => {
                let data = result.data
                let fileName = '1.xlsx'

                const contentDisposition = result.headers['content-disposition'];
                if (contentDisposition) {
                    const utf8ValueMatch = contentDisposition.match(/filename\*=utf-8''(.+)/);
                    if (utf8ValueMatch && utf8ValueMatch[1]) {
                        fileName = decodeURIComponent(utf8ValueMatch[1]);
                    }
                }

                fileDownload(data, fileName)
            })
    }
}

export default new organizationsController()
