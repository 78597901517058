import {Base64} from 'js-base64'

export const getCookie = (key, decrypted = true) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${key}=`)
    if(parts.length === 2){
        let cookieValue = parts.pop().split(';').shift()
        if(decrypted) return Base64.decode(cookieValue)
        else return cookieValue
    } else return null
}

export const saveCookie = (key, value, minutes = 525600, encrypted = true) => {
    const date = new Date()
    date.setTime(date.getTime() + (minutes*60*1000))
    if(encrypted) document.cookie = `${key}=${Base64.encode(value)};expires=${date.toUTCString()};path=/`
    else document.cookie = `${key}=${value};expires=${date.toUTCString()};path=/`
    return true
}

export const clearCookie = (key = null) => {
    if(!key) document.cookie.split(";").forEach(c => { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); })
    else document.cookie = `${key}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    return true
}