export const saveLocal = (key, value) => {
    localStorage.setItem(key, value)
    return true
}

export const getLocal = key => {
    return localStorage.getItem(key)
}

export const clearLocal = (key = null) => {
    if(!key) localStorage.clear()
    else localStorage.removeItem(key)
    return true
}