import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite"
import __global from "../../__global"
import {Col, Popover, Row} from "antd"
import {FormButton, FormInput, FormSelect} from "../../_components/FormElements"
import GlobalTableBlock from "../../_components/_table/GlobalTable"
import __organizations from "./__organizations";

const OrganizationsPage = observer(() => {
    useEffect(() => {
        __global.getList('organizations')
        __global.clearFilters()
    }, [])

    const columns = [
        {
            title: 'ID',
            key: 'id',
            sort: true
        },
        {
            title: 'Наименование',
            key: 'name_short',
            sort: true
        },
        {
            title: 'ИНН',
            key: 'inn'
        },
        {
            title: 'ОКВЭД',
            key: 'okved',
            type: 'customRender',
            render: (_, r) => (
                <Popover
                    title="ОКВЭДЫ"
                    content={(
                        <Row gutter={[9,9]}>
                            {r.okveds.map((el, index) => (<Col key={index}>{el}</Col>))}
                        </Row>
                    )}
                    className="cursor-pointer"
                >
                    {_}
                </Popover>
            )
        },
        {
            title: 'Пользователь',
            key: 'user',
            type: 'customRender',
            render: (_, r) => <>{r?.user ? (<>{r.user.name} (<b>id: {r.user.id}</b>)</>) : (<>Нет</>)}</>
        },
    ]

    return <>
        <div>
            <div className="workarea__section organizations">
                <Row gutter={[9, 9]}>
                    <Col xs={24}>
                        <form onSubmit={e => {
                            e.preventDefault()
                            __global.getList()
                        }}>
                            <Row gutter={[9, 9]}>
                                <Col xs={24} md={8} lg={6} xl={4}>
                                    <FormInput
                                        onChange={e => __global.changeFilters('name_short', e.target.value)}
                                        value={__global.filtersValues.name_short?.value}
                                        placeholder="Наименование"
                                    />
                                </Col>

                                <Col xs={24} md={8} lg={6} xl={4}>
                                    <FormInput
                                        onChange={e => __global.changeFilters('inn', e.target.value)}
                                        value={__global.filtersValues.inn?.value}
                                        placeholder="ИНН"
                                    />
                                </Col>

                                <Col>
                                    <FormButton
                                        htmlType="submit"
                                        className="form__button-blue"
                                    >Поиск</FormButton>
                                </Col>

                                <Col>
                                    <FormButton
                                        onClick={() => __global.clearFilters()}
                                        className="form__button-danger"
                                    >Очистить</FormButton>
                                </Col>

                                <Col>
                                    <FormButton
                                        onClick={() => __organizations.download()}
                                        className="form__button-green"
                                    >Скачать список</FormButton>
                                </Col>
                            </Row>
                        </form>
                    </Col>

                    <Col xs={24}>
                        <GlobalTableBlock
                            title="Список органиазаций"
                            columns={columns}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    </>
})

export default OrganizationsPage
