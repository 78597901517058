export const formatDateWithTime = (date) => {
    return new Date(
        Date.parse(date)
    ).toLocaleDateString("ru-RU") + " " + new Date(
        Date.parse(date)
    ).toLocaleTimeString("ru-RU", {timeStyle: 'short'})
}
export const formatDate = (date) => {
    return new Date(
        Date.parse(date)
    ).toLocaleDateString("ru-RU")
}
export const formatNumber = (number) => {
    return new Intl.NumberFormat('ru-RU', {maximumFractionDigits: 2}).format(number).replace(',', '.')
}
export const getYearsList = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()
    const currentQuarter = Math.floor(currentMonth / 3) + 1
    const years = [];
    for (let i = 0; i < 4; i++) {
        years.push(currentYear - i);
    }
    if (currentQuarter === 4) {
        years.unshift(currentYear + 1);
    }
    return years
}
