const FormatFilledTableCell = (item, color = "#7A9AF8") => {
    let min = 0
    let max = 100
    let value
    if(typeof item === 'string'){
        value = parseFloat(item.replace(/([^,.\d]+)/gi, '').replace(',', '.')).toFixed(2)
    } else {
        value = parseFloat(item).toFixed(2)
    }
    let filled = ((value - min) / (max - min)) * 100
    return "linear-gradient(to right, " + color + " 0% " + filled + "%, transparent " + filled + "% " + (100 - filled) + "%)"
}

export default FormatFilledTableCell
