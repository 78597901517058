import {makeAutoObservable, runInAction} from "mobx"
import __global from "../../__global"
import {message} from "antd"
import fileDownload from "js-file-download"

class invitesController {
    constructor() {
        makeAutoObservable(this)
    }

    rolloverOpened = false
    generateCount = null

    generate() {
        let data = new FormData()

        data.append('count', this.generateCount)
        if(Number(this.generateCount) === 0) {
            return message.error('Укажите количество генерируемых приглашений')
        }

        __global.request('post', 'invites/generate', data)
            .then(result => {
                __global.getList()
                message.success('Успешно')
                this.toggleRollover()
            })
    }

    download(type = 'free') {
        __global.request('get', `invites/download?type=${type}`, {}, true, {
            responseType: 'blob'
        })
            .then(result => {
                let data = result.data
                let fileName = '1.xlsx'

                const contentDisposition = result.headers['content-disposition'];
                if (contentDisposition) {
                    const utf8ValueMatch = contentDisposition.match(/filename\*=utf-8''(.+)/);
                    if (utf8ValueMatch && utf8ValueMatch[1]) {
                        fileName = decodeURIComponent(utf8ValueMatch[1]);
                    }
                }

                fileDownload(data, fileName)
            })
    }

    toggleRollover() {
        this.rolloverOpened = !this.rolloverOpened
    }
}

export default new invitesController()
