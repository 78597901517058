import {makeAutoObservable, runInAction} from "mobx"
import axios from 'axios'
import {message} from "antd"
import {getLocal} from "./_store/local"
import {getCookie} from "./_store/cookies"

class globalController {
    constructor() {
        makeAutoObservable(this)
    }

    activeRequests = 0
    waitForAnswer = false

    user = {
        id: 0,
        login: '',
        name: '',
        permissions: {
            id: 0,
            name: '',
            rules: []
        }
    }
    userAuthorised = false

    initialURL = ''

    list = []

    pagination = {
        total: 0,
        limit: 25,
        page: 1,
        orderDirection: 'desc',
        orderBy: 'id'
    }

    filtersValues = {}
    filters = {}

    getSettings() {
        const userInfo = document.getElementById('userInfo')

        if (userInfo) {
            this.user = JSON.parse(userInfo.innerHTML)[0]
            userInfo.remove()
        }

        const userTokenFromLocal = getLocal('User-Token')
        const userTokenFromCookies = getCookie('User-Token')
        if(userTokenFromLocal && userTokenFromCookies) {
            this.userAuthorised = true
        }
    }

    request(method, path, data = {}, block = true, params = {}) {
        if (block) this.startRequest()

        let headers = {}

        let userToken = getLocal('User-Token')
        if(userToken) {
            headers['User-Token'] = userToken
        }

        const req = axios.create({
            baseURL: '/api/v1/',
            headers: headers
        })

        const successCallback = result => {
            if (block) this.stopRequest()
            if (result?.headers?.['content-disposition']) return result
            return result.data
        }

        const errorCallback = error => {
            if (block) this.stopRequest()
            this.handleError(error?.response?.data?.message)
            throw error
        }

        if (method === 'get') {
            return req.get(path, params)
                .then(successCallback)
                .catch(errorCallback)
        } else if (method === 'post') {
            return req.post(path, data, params)
                .then(successCallback)
                .catch(errorCallback)
        } else if (method === 'delete') {
            return req.delete(path, data)
                .then(successCallback)
                .catch(errorCallback)
        }
    }

    handleError(text = null) {
        if(!text) text = 'Произошла ошибка'
        message.error(text)
        this.stopRequest()
    }

    redirect(path = '/') {
        window.location.href = path
    }

    startRequest() {
        this.activeRequests++
        this.waitForAnswer = true
    }

    stopRequest() {
        this.activeRequests--
        if (this.activeRequests === 0) this.waitForAnswer = false
    }

    getList(url = '') {
        if (this.initialURL === '') {
            this.initialURL = url
        }

        url = this.getURLQuery()

        this.request('get', url)
            .then(result => {
                let filters = {}

                Object.keys(result.filters).forEach(filterKey => {
                    filters[filterKey] = []
                    if (result.filters[filterKey].withEmpty === true) {
                        filters[filterKey].push({
                            label: 'Не указан',
                            value: '-'
                        })
                    }

                    result.filters[filterKey].list.forEach(filterValue => {
                        filters[filterKey].push({
                            label: filterValue.name,
                            value: filterValue.id
                        })
                    })
                })

                runInAction(() => {
                    this.list = result.list
                    this.filters = filters

                    this.pagination = {
                        total: result.total,
                        limit: result.limit,
                        page: result.page
                    }
                })

                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            })
            .catch(e => this.handleError())
    }

    setPage(page, size = this.pagination.limit) {
        this.pagination.page = page
        this.pagination.limit = size
        this.getList()
    }

    changeFilters(field, value, strict = false, isArray = false) {
        this.filtersValues[field] = {
            value: value,
            strict: strict,
            isArray: isArray
        }
    }

    clearFilters() {
        this.filtersValues = {}
        this.getList()
    }

    getURLQuery(url = this.initialURL, pagination = this.pagination, filtersValues = this.filtersValues) {
        let parameters = new URLSearchParams({ ...pagination })
        url = `${url}?${parameters}`

        Object.keys(filtersValues).forEach(key => {
            if (filtersValues[key]) {
                let value = filtersValues[key]['value']

                if (filtersValues[key]['isArray']) {
                    if (value.length === 0) {
                        value = null
                    } else {
                        value = JSON.stringify(value)
                    }
                }

                if (value) {
                    url += `&filters[${key}][value]=${value}`
                    url += `&filters[${key}][strict]=${Number(filtersValues[key]['strict'])}`
                    url += `&filters[${key}][isArray]=${Number(filtersValues[key]['isArray'])}`
                }
            }
        })

        return url
    }
}

export default new globalController()
