import React from 'react'
import {observer} from "mobx-react-lite"
import __global from "../__global"
import {Button, Input, Select, Tag} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";

export const FormInput = observer(({
                                       type = 'text',
                                       disabled = __global.waitForAnswer,
                                       value = '',
                                       onChange = null,
                                       placeholder = '',
                                       className = null
                                   }) => {
    let render = <></>
    let inputProps = {
        type: type,
        disabled: disabled,
        value: value,
        placeholder: placeholder,
        allowClear: true
    }

    if (onChange) {
        inputProps['onChange'] = onChange
    }

    let inputClassName = 'form__input'
    if (className) {
        inputClassName += ` ${className}`
    }
    inputProps['className'] = inputClassName

    if (type === 'password') {
        inputProps['iconRender'] = visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)

        render = <Input.Password {...inputProps} />
    } else {
        render = <Input {...inputProps} />
    }

    return render
})

export const FormButton = observer(({
                                        children,
                                        disabled = __global.waitForAnswer,
                                        onClick = null,
                                        className = null,
                                        htmlType = 'button'
                                    }) => {
    let buttonProps = {
        disabled: disabled,
        htmlType: htmlType
    }

    if (onClick) {
        buttonProps['onClick'] = onClick
    }

    let buttonClassName = 'form__button'
    if (className) {
        buttonClassName += ` ${className}`
    }
    buttonProps['className'] = buttonClassName

    return <Button {...buttonProps}>{children}</Button>
})

export const FormSelect = observer(({
    disabled = __global.waitForAnswer,
    onChange = null,
    placeholder = '',
    value = '',
    options = [],
    allowClear = false,
    mode = null,
    tagRender = null
}) => {
    let selectProps = {
        disabled: disabled,
        placeholder: placeholder,
        value: value,
        options: options,
        className: 'form__select',
        allowClear: allowClear,
        showSearch: false
    }

    if (onChange) {
        selectProps['onChange'] = onChange
    }

    if (mode) {
        selectProps['mode'] = mode
    }

    if (tagRender) {
        selectProps['tagRender'] = tagRender
    } else {
        selectProps['tagRender'] = props => {
            const { label, value, closable, onClose } = props
            return (
                <Tag
                    closable={closable}
                    onClose={onClose}
                >
                    {props.label}
                </Tag>
            )
        }
    }

    return <Select {...selectProps} />
})
