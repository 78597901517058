import React from 'react'
import {observer} from "mobx-react-lite"
import __auth from "./__auth"
import {FormInput, FormButton} from "../../_components/FormElements"

const AuthForm = observer(() => {
    return (
        <form onSubmit={e => __auth.authUser(e)} className="auth__form">
            <FormInput
                onChange={e => __auth.change('login', e.target.value)}
                value={__auth.user.login}
                placeholder="Логин"
            />

            <FormInput
                type="password"
                onChange={e => __auth.change('password', e.target.value)}
                value={__auth.user.password}
                placeholder="Пароль"
            />

            <FormButton
                htmlType="submit"
                className="form__button-blue"
            >Войти</FormButton>
        </form>
    )
})

const AuthPage = observer(() => {
    return (
        <>
            <div className="auth">
                <AuthForm/>
            </div>
        </>
    )
})

export default AuthPage
