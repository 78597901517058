import {createRoot} from 'react-dom/client'
import React, {useEffect, useState} from "react"
import __global from "../__global"
import {Layout} from 'antd'
import {DoubleLeftOutlined} from '@ant-design/icons'
import __auth from "./auth/__auth"
import AuthPage from "./auth/Auth"
import DashboardPage from "./dashboard/Dashboard"
import UsersPage from "./users/Users"
import OrganizationsPage from "./organizations/Organizations"
import LeadsIcon from '../../img/icons/leadsIcon.svg'
import UsersIcon from '../../img/icons/usersIcon.svg'
import OrgsIcon from '../../img/icons/orgsIcon.svg'
import LogoutIcon from '../../img/icons/logoutIcon.svg'




import SettingsIcon from '../../img/icons/settingsIcon.svg'
import InviteIcon from '../../img/icons/inviteIcon.svg'
import SaveIcon from '../../img/icons/saveIcon.svg'
import QueryIcon from '../../img/icons/queryIcon.svg'
import LimitsIcon from '../../img/icons/limitsIcon.svg'
import PlusSmallIcon from '../../img/icons/plusSmallIcon.svg'
import InvitesPage from "./invites/Invites";

const {Header, Sider, Content} = Layout

__global.getSettings()

const pagesList = [
    {
        'id': 'authPage',
        'excludeFromMenu': true,
        'auth': false
    },
    {
        'title': 'Дашборд',
        'id': 'dashboardPage',
        'component': (<DashboardPage />),
        'icon': LeadsIcon,
        'href': '/',
        'position': 1,
        'auth': true
    },
]

if (__global.user?.permissions?.level > 0) {
    pagesList.push({
        'title': 'Организации',
        'id': 'organizationsPage',
        'component': (<OrganizationsPage />),
        'icon': OrgsIcon,
        'href': '/organizations',
        'position': 10,
        'auth': true
    })
    pagesList.push({
        'title': 'Пользователи',
        'id': 'usersPage',
        'component': (<UsersPage />),
        'icon': UsersIcon,
        'href': '/users',
        'position': 20,
        'auth': true
    })
    pagesList.push({
        'title': 'Приглашения',
        'id': 'invitesPage',
        'component': (<InvitesPage />),
        'icon': InviteIcon,
        'href': '/invites',
        'position': 5,
        'auth': true
    })
}


// const pagesList = [

//     {
//         'title': 'Продажи',
//         'id': 'salesPage',
//         'component': (<SalesPage/>),
//         'icon': LimitsIcon,
//         'href': '/sales',
//         'position': 4
//     },

//     {
//         'title': 'Клиенты',
//         'id': 'clientsPage',
//         'component': (<Orgs type="clients"/>),
//         'icon': OrgsIcon,
//         'href': '/clients',
//         'position': 10
//     },
//     {
//         'title': 'Заявки из бота',
//         'id': 'queriesPage',
//         'component': (<QueriesPage/>),
//         'icon': QueryIcon,
//         'href': '/queries',
//         'position': 2
//     },
//     {
//         'title': 'Из реестра',
//         'id': 'readerPage',
//         'component': (<ReaderPage/>),
//         'icon': SaveIcon,
//         'href': '/reader',
//         'position': 3
//     },
//     {
//         'title': 'Заявка',
//         'id': 'generatorPage',
//         'component': (<GeneratorPage/>),
//         'icon': PlusSmallIcon,
//         'href': '/new',
//         'position': 2
//     },
// ]


// if (__global.user?.permissions === 0) {
//     pagesList.push(
//         {
//             'title': 'Личный кабинет',
//             'id': 'settingsPage',
//             'component': (<UserPage/>),
//             'icon': SettingsIcon,
//             'href': '/settings',
//             'position': 200
//         }
//     )
// }
//
//
// if (__global.user?.permissions > 0) {
//     pagesList.push(
//         {
//             'title': 'Заявки',
//             'id': 'generationsPage',
//             'component': (<GenerationsPage/>),
//             'icon': LeadsIcon,
//             'href': '/',
//             'position': 1
//         },
//         {
//             'title': 'Инвайты',
//             'id': 'invitesPage',
//             'component': (<Invites/>),
//             'icon': InviteIcon,
//             'href': '/invites',
//             'position': 100
//         },
//         {
//             'title': 'Пользователи',
//             'id': 'settingsPage',
//             'component': (<SettingsPage/>),
//             'icon': SettingsIcon,
//             'href': '/settings',
//             'position': 200
//         }
//     )
// }

pagesList.sort((a, b) => a.position - b.position)

let page
let pageItem = null
let currentPage
for (let i = 0; i < pagesList.length; i++) {
    let item = pagesList[i]
    let pageNode = document.getElementById(item.id)
    if (pageNode) {
        if (__global.userAuthorised && item.auth === false) {
            __global.redirect()
        } else if (!__global.userAuthorised && item.auth === true) {
            __global.redirect('/auth')
        } else {
            page = pageNode
            pageItem = item
            currentPage = item.id
            break;
        }
    }
}

if (!pageItem) {
    __global.redirect(__global.userAuthorised ? '/' : '/auth')
} else {
    if (__global.userAuthorised && pageItem.auth === false) {
        __global.redirect()
    } else if (!__global.userAuthorised && pageItem.auth === true) {
        __global.redirect('/auth')
    }
}

const PageLayout = ({item}) => {
    useEffect(() => {

    }, [])

    const [collapsed, setCollapsed] = useState(true)
    if (currentPage === 'authPage') return <AuthPage />

    if (__global.user?.id < 1) return __global.redirect('/auth')

    return (
        <Layout className="layout">
            <Sider
                collapsedWidth={70}
                width={186}
                collapsible
                collapsed={collapsed}
                onCollapse={value => setCollapsed(value)}
                trigger={<DoubleLeftOutlined/>}
            >
                <div className="menu">
                    {pagesList.map((item, i) => {
                        if (item?.excludeFromMenu === true) return null
                        let className = 'menu__item'
                        if (currentPage === item.id) className += ' menu__item-active'
                        return (
                            <a
                                href={item.href}
                                className={className}
                                key={i}
                            >
                                <div className="menu__item-icon">
                                    <img src={item.icon} alt={item.title}/>
                                </div>
                                <div className="menu__item-text">
                                    {item.title}
                                </div>
                            </a>
                        )
                    })}
                    <a
                        href="#"
                        className="menu__item"
                        onClick={_ => __auth.logOut()}
                    >
                        <div className="menu__item-icon">
                            <img src={LogoutIcon} alt=""/>
                        </div>
                        <div className="menu__item-text">
                            Выйти
                        </div>
                    </a>
                </div>
            </Sider>
            <Layout>
                <Header className="header">
                    <div className="header__title">
                        <img src={item.icon} alt={item.title}/>
                        {item.title}
                    </div>
                    <div className="header__menu">
                        {/*<a href="/profile" className="item item-profile">{__global.user.login}</a>*/}
                        <div className="item item-profile">{__global.user.login}</div>
                    </div>
                </Header>
                <Content className="workarea">
                {item.component}
                </Content>
            </Layout>
            <div className="rollover__mask"></div>
        </Layout>
    )
}

if(pageItem) {
    const root = createRoot(page)
    root.render(<PageLayout item={pageItem}/>)
}

